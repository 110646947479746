import { createApp } from 'vue';

import "vue-loading-overlay/dist/css/index.css";
import "@/assets/styles/style.scss";

import '@/utils/validate';

import App from './App';
import { store } from './store'
import router from './router';

const VueApp = window.VueApp = createApp(App);
VueApp.use(store);
VueApp.use(router);
VueApp.mount('#app');

import VueFilters from '@rosfines/vue-common/vue/util/vue.filters';
VueApp.config.globalProperties.$filters = VueFilters;


if (process.env.NODE_ENV === 'production' && window.ym_whitelist_paths.includes(location.pathname)) {
  require('./assets/scripts/metrika');
}
