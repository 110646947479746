import api from '@/services/api';
import config from '@/config';

export const eventIds = {
  appLoaded: 'webview_exp_app_loaded',
  modalShown: 'webview_exp_modal_show',
  modalClosed: 'webview_exp_modal_close',
  validationError: 'webview_exp_validation_error',
  inputFocus: 'webview_exp_input_focus',
  inputBlur: 'webview_exp_input_blur',
  radioClicked: 'webview_exp_radio_button_select',
  checkboxClicked: 'webview_exp_checkbox_select',
  btnClicked: 'webview_exp_button_pressed',
  pageLoaded: 'webview_exp_page_shown',
  actionsDone: 'webview_actions_done',
};

export const statService = {
  channel: 'WebVersion',

  setStatServiceDataParam($route, amplitudeData = {}, userProperties = {}) {
  },

  init({channel}) {
    this.channel = channel;
  },

  logEvent(eventId, categoryId, eventProperties = {}) {
    api.post("/105/api/userAction/tracking", {
      ...api.getRequestParams(),
      channel: this.channel,
      event: eventId,
      newResponse: "1",
      params: {
        category: categoryId,
        ...eventProperties,
      },
    })
    if(window.ym_whitelist_paths.includes(location.pathname)) {
      window.ym && window.ym(config.YM_SITEKEY, 'reachGoal', `${eventId} - ${categoryId}`);
    }
  },
}
